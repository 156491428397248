<template>
	<div class='container'>
		<div class="title">
      <span class="inner_title">住户管理</span>
    </div>
    <div class="operation">
      <el-button type="primary" @click="addClick">添加成员</el-button>
    </div>
    <div class="cont_container" style="padding-top:20px">
      <template>
        <el-table class="table"  
        v-loading="loading"  
        :element-loading-text="loadingText" 
        element-loading-spinner="el-icon-loading"
        :element-loading-background="loadingColor" 
        :data="zhuHuList" 
        :header-cell-style="TableTitleBackColor" 
        :row-style="tableCss" 
        :highlight-current-row="true" 
        :cell-style="cellStyle" >
          <el-table-column prop="building_number" label="楼栋">
          </el-table-column>
          <el-table-column prop="unit_number" label="单元">
          </el-table-column>
          <el-table-column prop="house_number" label="房号">
          </el-table-column>
          <el-table-column prop="user_name" label="姓名">
          </el-table-column>
          <el-table-column prop="mobile" label="电话">
          </el-table-column>
          <el-table-column prop="sex" label="性别">
          </el-table-column>
          <el-table-column prop="type_txt" label="身份类型">
          </el-table-column>
          <el-table-column prop="id_number" label="身份证号">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>
              <el-button size="mini" style="margin-top: 10px;" type="danger" @click="deleteZhuHu(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
		<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
		 :current-page="page" @current-change="pageChange">
		</el-pagination>
	</div>
</template>
<script>
	import tableCss from '../../../style/tableCss.js';
	import {
		mapState
	} from "vuex";
  import {getZhuHuListFn,deleteZhuHuFn} from '@/api/zhuHu.js'
	export default {
		props: [],
		data() {
			return {
				loading: false,
				zhuHuList: [],
				page: 1,
				page_size: 10,
				last_page: 0,
			}
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
    created() {
      this.house_number = this.$route.query.id
    },
    mounted() {
			this.getZhuHuList();
		},
    // watch:{
    //   $route(to,from){
    //     if (!this.$route.query.id){
    //       this.house_number = ''
    //       this.getZhuHuList()
    //     }
    //   }
    // },
		methods: {
      //删除住户
      deleteZhuHu(row){
        let params = {
          api_token: localStorage.getItem('token1'),
          id:row.id
        }
        this.$confirm('确定删除', '确定删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          deleteZhuHuFn(params).then(res => {
            this.getZhuHuList()
          })
        });
      },
      //获取住户列表
			getZhuHuList() {
				this.loading = true;
				getZhuHuListFn({
          api_token:localStorage.getItem('token1'),
					page: this.page,
					page_size: this.page_size,
          house_number:this.house_number,
				}).then(res => {
					this.zhuHuList = res.datas.data;
					this.last_page = res.meta.last_page;
					this.loading = false;
				})
			},
      //新增
			addClick() {
				this.$router.push({
					path: '/addZhuHu'
				});
			},
			//编辑
			editClick(row) {
				this.$router.push({
					path: '/editZhuHu',
					query: {
						id: row.id,
						page: this.page,
						last_page: this.last_page,
					}
				});
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getZhuHuList();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
		activated() {

		}
	}
</script>
<style scoped lang='scss'>
	@import url("../../../style/title.css");
  
</style>
